import { HttpErrorResponse } from '@angular/common/http';
import { Action, createReducer, on } from '@ngrx/store';
import * as UserActions from './user.actions';

export const userFeatureKey = 'user';

type Features = {
  [key: string]: boolean;
};

export interface State {
  loggedIn: boolean;
  inProgress: boolean;
  step: AuthStep;
  name: string;
  token: string;
  eula: string;
  permissions?: {
    expires_time?: null;
    audit_reports: boolean;
    report_count: null;
    report_quota: null;
    data_volume_count: null;
    data_volume_quota: null;
    admin_ui: number
  };
  groups: string[];
  error?: any;
  features?: Features
  errors: {
    forgotPassword?: any
  },
  accountStatus: UserAccountStatus;
  quotas: {
    reportsProcessed: number;
    reportsQuota: number;
  }
  verificationEmails: VerificationEmailStatus[],
  firstName: string,
  lastName: string
}

export interface UserAccountStatus {
  eulaAccepted: boolean;
  emailVerified: boolean;
  ok: boolean;
}

export interface VerificationEmailStatus {
  timeSent: number;
  error?: any;
}

export enum AuthStep {
  Authenticated = '[Authenticated]',
  Unauthenticated = '[Unauthenticated]',
  Initial = '[Initial]',
  SignIn = '[SignIn]',
  LoadUser = '[LoadUser]',
  ChangePassword = '[Change Password]',
  Signup = '[Signup]',
  ForgotPassword = '[Forgot Password]'
}

export const initialState: State = {
  loggedIn: false,
  inProgress: true,
  name: null,
  token: null,
  permissions: null,
  groups: [],
  step: AuthStep.Initial,
  eula: '',
  errors: {},
  accountStatus: null,
  quotas: {
    reportsProcessed: null,
    reportsQuota: null
  },
  verificationEmails: [],
  firstName: null,
  lastName: null
};


export const reducer = createReducer(
  initialState,

  on(UserActions.loadUser, state => ({
    ...state,
    inProgress: true,
    error: null,
    step: AuthStep.LoadUser
  })),
  on(UserActions.loadUserSuccess, (state, action) => ({
    ...state,
    error: null,
  })),
  on(UserActions.loadUserFailure, (state, { error }) => ({
    ...initialState,
    eula: state.eula,
    error,
    step: AuthStep.LoadUser
  })),
  on(UserActions.getUserStatusSuccess, UserActions.getUserStatusFail, state => {
    return {
      ...state,
      inProgress: false,
      step: AuthStep.Authenticated
    }
  }),
  on(UserActions.noLocalUser, state => {
    return {
      ...state,
      inProgress: false,
      error: null,
      step: AuthStep.Unauthenticated
    }
  }),
  on(UserActions.signIn, (state, { username }) => {
    return {
      ...state,
      inProgress: true,
      error: null,
      name: username,
      step: AuthStep.SignIn
    }
  }),
  on(UserActions.signInFail, (state, { error }) => {
    const changePasswordRequired = error && (error as HttpErrorResponse).status === 403;
    const step = changePasswordRequired ? AuthStep.ChangePassword : AuthStep.SignIn;
    return {
      ...initialState,
      eula: state.eula,
      inProgress: false,
      error,
      step
    }
  }),
  on(UserActions.signInSuccess, (state, user) => {
    return {
      ...state,
      loggedIn: true,
      inProgress: false,
      error: null,
      name: user.user_name,
      token: user.auth_token,
      permissions: user.permissions,
      step: AuthStep.Authenticated
    };
  }),
  on(UserActions.signup, (state) => {
    return {
      ...state,
      error: null,
      step: AuthStep.Signup
    }
  }),
  on(UserActions.signupFail, (state, { error }) => {
    return {
      ...state,
      error,
      step: AuthStep.Signup
    }
  }),
  on(UserActions.signupSuccess, (state) => {
    return {
      ...state,
      error: null,
      step: AuthStep.Signup
    }
  }),
  on(UserActions.userLoaded, (state, user) => {
    return {
      ...state,
      loggedIn: true,
      error: null,
      name: user.user_name,
      permissions: user.permissions,
      token: user.auth_token,
      credentials: user.credentials,
      description: user.description,
      features: user.features,
      groups: user.groups,
      step: AuthStep.Authenticated,
      firstName: user.first_name,
      lastName: user.last_name
    }
  }),
  on(UserActions.changePassword, state => {
    return {
      ...state,
      inProgress: true,
      step: AuthStep.ChangePassword
    }
  }),
  on(UserActions.changePasswordSuccess, state => {
    return {
      ...state,
      inProgress: false,
      error: null,
      step: AuthStep.ChangePassword
    }
  }),
  on(UserActions.changePasswordError, (state, { error }) => {
    return {
      ...state,
      inProgress: false,
      error,
      step: AuthStep.ChangePassword
    }
  }),
  on(UserActions.sessionExpired, state => {
    return {
      ...state,
      loggedIn: false,
      error: null,
      step: AuthStep.Unauthenticated
    }
  }),
  on(UserActions.signOut, (state, { error }) => {
    return {
      ...initialState,
      inProgress: false,
      eula: state.eula,
      error,
      step: AuthStep.Unauthenticated
    };
  }),
  on(UserActions.setEULA, (state, { eula }) => {
    return {
      ...state,
      eula
    }
  }),
  on(
    UserActions.sendForgotPasswordEmail,
    UserActions.sendForgotPasswordEmailSuccess,
    state => {
      return {
        ...state,
        step: AuthStep.ForgotPassword,
        errors: {
          ...state.errors,
          forgotPassword: null
        }
      }
    }),
  on(UserActions.sendForgotPasswordEmailFail, (state, { error }) => {
    return {
      ...state,
      step: AuthStep.ForgotPassword,
      errors: {
        ...state.errors,
        forgotPassword: error
      }
    }
  }),
  on(UserActions.getUserStatusSuccess, (state, { EULA_accepted, email_verified, user_ok, reports_processed, reports_quota }) => {
    return {
      ...state,
      accountStatus: {
        emailVerified: email_verified,
        eulaAccepted: EULA_accepted,
        ok: user_ok
      },
      quotas: {
        reportsProcessed: reports_processed,
        reportsQuota: reports_quota
      }
    }
  }),
   on(UserActions.getQuotasSuccess, (state, { reports_processed, reports_quota }) => {
    return {
      ...state,
      quotas: {
        reportsProcessed: reports_processed,
        reportsQuota: reports_quota
      }
    }
  }),
  on(UserActions.sendVerificationEmailFail, (state, { error }) => {
    const verificationEmails = [...state.verificationEmails, {
      timeSent: Date.now(),
      error
    }];
    return {
      ...state,
      verificationEmails
    }
  }),
  on(UserActions.sendVerificationEmailSuccess, (state, { }) => {
    const verificationEmails = [...state.verificationEmails, {
      timeSent: Date.now(),
      error: null
    }];
    return {
      ...state,
      verificationEmails
    }
  }),
  on(UserActions.clearError, state => ({
    ...state,
    error: null
  }))
);

export function aotState(state: State | undefined, action: Action) {
  return reducer(state, action);
}

